import { n as normalizeComponent } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "title": "Assign Facility",
            "after-close": _vm.afterModalClose
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.onCancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" Assign to New Facility ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Country",
            "value": _vm.entity.countryCode,
            "reference": "identities.applications.contexts",
            "custom-query": "app=fas&entityLevel=country",
            "source": "entityLevelCode",
            "source-label": "entityLevelName"
          },
          on: {
            "change": _vm.onNationChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Facility",
            "rules": "required",
            "value": _vm.entity.entityId,
            "data-source": _vm.listFacilities,
            "source": "entityId",
            "source-label": "entityLevelName"
          },
          on: {
            "change": _vm.onFacilityChange
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "AssignFacility",
  inject: ["crud", "redirectRoute", "apiUrl"],
  data() {
    return {
      visible: false,
      listFacilities: [],
      entityId: "",
      userId: this.$route.params.id
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  async mounted() {
    this.visible = true;
  },
  async created() {
    if (this.entity) {
      this.getListFacilities(this.entity.countryCode);
    }
  },
  methods: {
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onCancel() {
      this.visible = false;
    },
    async getListFacilities(countryCode) {
      const { data } = await this.axios.get(`${this.apiUrl}/identities/applications/contexts?app=fas&entityLevel=facility&entityParentCode=${countryCode}`);
      this.listFacilities = data;
    },
    onNationChange(value) {
      this.getListFacilities(value);
    },
    onFacilityChange(value) {
      this.entityId = value;
    },
    async submit() {
      const enableData = {
        type: "enable",
        roleCode: "userFasR"
      };
      const deleteData = {
        type: "delete",
        roleCode: "userFasR"
      };
      const url = `${this.apiUrl}/identities/users/${this.userId}/rights`;
      const { roleCode, entityId } = this.entity;
      if (roleCode != null && entityId != null) {
        await this.axios.put(url, deleteData, {
          params: { entityContext: entityId }
        });
      }
      await this.axios.put(url, enableData, {
        params: { entityContext: this.entityId ? this.entityId : entityId }
      });
      await this.crud.fetchList();
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var AssignFacility = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "identities.applications.contexts",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.assetregisters.getlistusers",
      "api-url": _vm.apiUrl,
      "redirect-route": "/assign-facility"
    }
  }, [_c("assign-facility")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { AssignFacility },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
